@import "../../styles/variables";
.usage-condition-section {
  .custom-card {
    min-height: 500px;
  }
  .paragraph-content {
    margin-top: 9px;
    display: flex;
    justify-content: space-between;
    background: $logged-color;
    border-radius: 4px;
    .title-dt {
      padding: 4px 10px 4px 10px;
    }
  }
}
