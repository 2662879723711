@import "../../styles/variables";

.chart-content {
  .student-details {
    .bottom-line-students {
      border-bottom: 2px solid $secondary-color;
    }

    .wrap-content {
      .icon-size {
        margin-right: 10px;
        width: 48px;
        height: 48px;
        border-radius: 50px;
        padding: 11px;
      }
    }
    .total-num {
      font-weight: 600;
    }
    .bottom-content {
      border-bottom: 2px solid $primary-color;
    }

    .primary-bg {
      background-color: $primary-color;
    }

    .secondary-bg {
      background-color: $secondary-color;
    }
    .profile-content {
      display: flex;
      margin-top: 10px;
      align-items: baseline;
    }
    .percent {
      margin: 0px 5px 0px 12px;
      color: $green-color;
      font-size: 12px;
    }
  }
}
