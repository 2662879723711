.reset-section {
  .custom-card {
    min-height: 500px;
  }
  height: 100%;
  .reset-checkbox:nth-child(6) {
    border-bottom: none;
  }
  .reset-topic {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rst-topic-acc {
    margin: 19px 0px 22px 0px;
  }
  .reset-name {
    margin-left: 8px;
  }
}
