@import "../../styles/variables";
.linegraph-section {
  .graph-details {
    .graph-topic {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
}
