@import "./variables";

@mixin btn-style {
  border: 1px solid $primary-color;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
}
@mixin check-box {
  width: 30px;
  height: 30px;
}

@mixin reset-section {
  display: flex;
  margin-top: 20px;
  border-bottom: 1px solid $grey;
  padding-bottom: 22px;
}

@mixin blocking-user {
  margin-top: 12px;
  border-bottom: 1px solid $grey;
  padding-bottom: 16px;
  width: 100%;
}

@mixin same-row {
  display: flex;
  align-items: center;
}

@mixin close-righticon {
  margin-left: 8px;
}

@mixin checkbox-btn {
  width: 24px;
  height: 24px;
}
