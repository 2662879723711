$box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
  0px 0px 1px rgba(0, 0, 0, 0.04);
$back-ground: #ffffff;
$white: #ffffff;
$grey: #e5e5e5;
$primary-color: #29abe2;
$secondary-color: #e26029;
$logged-color: rgba(41, 171, 226, 0.25);
$green-color: #24a55a;
$container-background: #e5e5e5;
$black-color: #000;
$medium-content: #616161;
$paragraph-color: #666;
$content-color: #00000059;
