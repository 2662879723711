@import "../../styles/variables";
.empty-state-part {
  .empty-state {
    width: 200px;
    height: 170px;
    display: flex;

    margin-top: 57px;
    margin-left: auto;
    margin-right: auto;
  }
  .content-section {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    .paragraph {
      margin-top: 4px;
      color: $paragraph-color;
    }
    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: $black-color;
    }
  }

  .empty-content {
    display: flex;
    justify-content: center;
  }
}
