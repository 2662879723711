@import "../styles/mixin";
@import "../styles/variables";
body {
  background-color: #f3f6f7;
  font-family: "Poppins", sans-serif;
}

p {
  margin: 0 !important;
}
.custom-card {
  background: $white;
  padding: 16px 24px;
  box-shadow: $box-shadow;
  border-radius: 4px;
}
.section-gap {
  padding: 20px 0;
}

.section-block-gap {
  padding: 32px 0 48px 0;
}
.mid-section {
  margin-top: 24px;
}
.line-content {
  margin: 0px 12px 8px 5px;
}

.medium-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: $medium-content;
  margin-bottom: 0;
}

.content {
  font-size: 12px;
  line-height: 18px;
  color: $content-color;
}
.primary-btn {
  @include btn-style;
  background: $primary-color;
  color: $white;
}
.btn-second {
  @include btn-style;
  color: $primary-color;

  background: $white;
}
.rst-line {
  margin: 22px 0px 8px 0px;
}
.rst-name {
  margin-left: 8px;
}
.line-bottom {
  border-bottom: 1px solid $grey;
  margin: 16px 0px 16px 0px;
}
.title-content {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
}
.num-content {
  font-weight: 500;
  font-size: 24px;
}
.reset-line {
  border-bottom: 1px solid #e5e5e5;
}
.location {
  margin-bottom: 8px;
}
.check-box {
  @include check-box;
}
.image-eyeglass {
  position: absolute;
  top: 5px;
  left: 11px;
}
.search-box {
  margin: 12px 0;
  position: relative;

  input {
    padding-left: 32px;
  }
}
.reset-checkbox {
  @include reset-section;
  align-items: center;
  input {
    width: 20px;
    height: 20px;
  }
}

.close-right-icon {
  @include close-righticon;
}

@media (min-width: 1500px) {
  .container {
    // background: #F3F6F7;
    max-width: 1485px !important;
  }
}
