@import "../../styles/variables";
@import "../../styles/mixin";
.block-user-section {
  .custom-card {
    min-height: 500px;
  }
  .school-content {
    display: flex;
    @include blocking-user;

    &:last-child {
      border-bottom: none;
    }

    .school-details {
      margin-left: 10px;

      .content {
        font-weight: 500;
        margin-bottom: 8px !important;
      }
    }
  }

  .school-name {
    @include same-row;

    .medium-title {
      color: $black-color;
    }
  }

  .wrapper {
    .school {
      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }
}
